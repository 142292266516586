/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState, useEffect } from "react"
import { useSpring, animated, config } from "@react-spring/three"
import { useFrame } from "@react-three/fiber"
import { useGLTF } from "@react-three/drei"

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF("/WD_logo.gltf")

  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto"
  }, [hovered])

  useEffect(() => {
    document.getElementById("anim-text").style.opacity = active ? "1" : "0"
    document.getElementById("anim-text").style.transform = active
      ? "translate3d(0, -20px, 0)"
      : "translate3d(0, 75px, 0)"
  }, [active])

  useFrame(({ clock }) => {
    group.current.position.z = Math.sin(clock.getElapsedTime()) * 2
  })

  const { color } = useSpring({
    color: hovered ? "#e40475" : "#48e0e4",
  })

  const { rotation, scale, position } = useSpring({
    scale: active ? [0.05, 0.05, 0.05] : [0.06, 0.06, 0.06],
    rotation: active ? [0, 0, 0] : [0, 0.5, 0],
    position: active ? [0, 2, 0] : [1, -2, 0],
    config: { tension: 300, friction: 15 },
  })

  return (
    <animated.group
      ref={group}
      {...props}
      dispose={null}
      scale={scale}
      rotation={rotation}
      position={position}
      onClick={event => setActive(!active)}
      onPointerOver={event => setHover(true)}
      onPointerOut={event => setHover(false)}
    >
      <group position={[-32, 100, -1.71]}>
        {/* <animated.mesh
          geometry={nodes.Path_1.geometry}
          material={nodes.Path_1.material}
          position={[-44.37, -0.17, 0]}
          material-color={color}
        />
        <animated.mesh
          geometry={nodes.Path_2.geometry}
          material={nodes.Path_2.material}
          position={[-144.06, -0.17, 0]}
          material-color={color}
        />
        <animated.mesh
          geometry={nodes.Path_3.geometry}
          material={nodes.Path_3.material}
          position={[144.06, 0.17, 0]}
          material-color={color}
        /> */}
        <animated.mesh
          geometry={nodes.Path_1.geometry}
          material={nodes.Path_1.material}
          position={[-44.37, -0.17, 0]}
        >
          <animated.meshStandardMaterial
            attach="material"
            color={color}
            metalness={0.7}
            roughness={0.2}
          />
        </animated.mesh>
        <animated.mesh
          geometry={nodes.Path_2.geometry}
          material={nodes.Path_2.material}
          position={[-144.06, -0.17, 0]}
        >
          <animated.meshStandardMaterial
            attach="material"
            color={color}
            metalness={0.7}
            roughness={0.2}
          />
        </animated.mesh>
        <animated.mesh
          geometry={nodes.Path_3.geometry}
          material={nodes.Path_3.material}
          position={[144.06, 0.17, 0]}
        >
          <animated.meshStandardMaterial
            attach="material"
            color={color}
            metalness={0.7}
            roughness={0.2}
          />
        </animated.mesh>
      </group>
    </animated.group>
  )
}

useGLTF.preload("/WD_logo.gltf")
